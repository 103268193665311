<template>
  <div class="wrapper-about text-white">
    <div class="about-section">
      <section class="breadcrumb py-2">
        <a-breadcrumb
          separator=">"
          class="w-full"
        >
          <a-breadcrumb-item class="cursor-pointer"
            ><span @click="() => $router.push('/')">{{
              $t('HOME_BREAD_CRUMB_TEXT')
            }}</span></a-breadcrumb-item
          >
          <a-breadcrumb-item href="#">{{ $t('ABOUT_ZAIFINO') }}</a-breadcrumb-item>
        </a-breadcrumb>
      </section>
    </div>
    <div class="support-chains pt-24 pb-16 bg-purpleDark">
      <div class="about-section">
        <div class="title text-3xl font-semibold">{{ $t('ABOUT_TITLE') }}</div>
        <div class="radient-purple-box mb-10">
          <div>{{ $t('ABOUT_CHAINS_SUPPORTED') }}</div>
          <div class="grid-container">
            <div
              class="chain-currencies-icon"
              v-for="chain in chains"
              :key="chain" 
            >
              <CryptoIcon
                iconCss="w-24 h-24"
                :symbol="chain"
              ></CryptoIcon>
              <div class="text-base font-thin tracking-widest overflow-hidden whitespace-nowrap text-overflow-ellipsis">{{ formatChainName(chain)  }}</div>
            </div>
          </div>
        </div>
        <div class="radient-purple-box mb-10">
          <div>{{ $t('ABOUT_CHAINS_CURRENCIES') }}</div>
            <div class="grid-container">
              <div
                class="chain-currencies-icon"
                v-for="currency in currencies"
                :key="currency"
              >
              <CryptoIcon
                iconCss="w-24 h-24"
                :symbol="currency"
              ></CryptoIcon>
              <div 
                class="text-base font-thin tracking-widest overflow-hidden whitespace-nowrap text-overflow-ellipsis"
                v-html="formatCurrencyName(currency)"
              ></div>
            </div>
          </div>
        </div>
        <div class="radient-purple-box">
          <div>{{ $t('ABOUT_NFT_TYPE_SUPPORTED') }}</div>
          <div class="text-center text-2xl mt-1">ERC - 721</div>
        </div>
      </div>
    </div>
    <div class="about-section">
      <div class="title text-3xl font-semibold mt-14">{{ $t('ABOUT_FAQ_TITLE') }}</div>
      <div class="bg-purpleDark p-16 rounded-2xl mt-11">
        <div
          class="mb-7 w-full radient-purple-box decrease-padding"
          v-for="(faq, index) in faqs"
          :key="index"
        >
          <div
            @click="() => handleOpen(index)"
            class="question text-2xl font-semibold flex items-center space-x-4"
          >
            <div>
              <QuestionIcon></QuestionIcon>
            </div>
            <div class="flex-grow">{{ $t(faq.question) }}</div>
            <div
              :ref="`chevronToggle${index}`"
              class="chevron-toggle"
            >
              <ChevronDown></ChevronDown>
            </div>
          </div>
          <transition name="slide-fade">
            <div
              v-if="faq.isOpen"
              class="text-lg font-thin answer mt-1 flex space-x-4"
            >
              <div class="answer-icon"><AnswerIcon></AnswerIcon></div>
              <div class="flex-grow bg-purpleDark px-5 py-4 rounded-2xl">{{ $t(faq.answer) }}</div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoIcon from '@/components/common/CryptoIcon.vue';
import QuestionIcon from '@/components/icons/question.icon.vue';
import AnswerIcon from '@/components/icons/answer.icon.vue';
import ChevronDown from '@/components/icons/chev-down-outlined.icon.vue';

export default {
  components: { CryptoIcon, QuestionIcon, AnswerIcon, ChevronDown },
  data() {
    return {
      chains: ['Ethereum', 'Polygon', 'BNBSmartChain', 'AstarNetwork', 'Avalanche', 'TCGVerse', 'MCHVerse', 'HOMEVerse'],
      currencies:['YEN','ETH', 'MATIC', 'BNB', 'ASTR', 'AVAX', 'OAS', 'CICC'],
      faqs: [
        {
          question: 'ABOUT_QUESTION_1',
          answer: 'ABOUT_ANSWER_1',
          isOpen: false,
        },
        {
          question: 'ABOUT_QUESTION_2',
          answer: 'ABOUT_ANSWER_2',
          isOpen: false,
        },
        {
          question: 'ABOUT_QUESTION_3',
          answer: 'ABOUT_ANSWER_3',
          isOpen: false,
        },
        {
          question: 'ABOUT_QUESTION_4',
          answer: 'ABOUT_ANSWER_4',
          isOpen: false,
        },
        {
          question: 'ABOUT_QUESTION_5',
          answer: 'ABOUT_ANSWER_5',
          isOpen: false,
        },
      ],
    };
  },
  methods: {
    handleOpen(index) {
      this.faqs[index].isOpen = !this.faqs[index].isOpen;
      console.log(this.$refs[`chevronToggle${index}`][0].classList.toggle('rotate-arrow'));
    },
    // 表示用の通貨名を整形するメソッド
    formatCurrencyName(currency) {
      // ここで`currency`の値に応じた整形を行う
      if(currency === 'YEN') return '日本円<br>(クレジットカード決済)';
      return currency;
    },

    // 表示用のチェーン名を整形するメソッド
    formatChainName(chain) {
      // ここで`chain`の値に応じた整形を行う
      if(chain === 'BNBSmartChain') return 'BNB Smart Chain';
      if(chain === 'AstarNetwork') return 'Astar Network';
      if(chain === 'MCHVerse') return 'MCH Verse';
      if(chain === 'TCGVerse') return 'TCG Verse';
      if(chain === 'HOMEVerse') return 'HOME Verse';
      return chain;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.wrapper-about {
  @apply w-full;
  @apply bg-purple-bg;
}
.about-section {
  @apply py-2 px-8;
  @apply mx-auto;
  max-width: 100%;
  @screen lg {
    max-width: 957px;
  }
  ::v-deep.breadcrumb {
    .ant-breadcrumb {
      span {
        .ant-breadcrumb-link {
          @apply text-white;
        }
        .ant-breadcrumb-separator {
          color: #fff;
        }
      }
    }
  }
  .radient-purple-box {
    background: linear-gradient(
      92.99deg,
      rgba(77, 91, 164, 0.3) 5.41%,
      rgba(128, 97, 205, 0.227038) 34.47%,
      rgba(77, 74, 186, 0.175914) 61.19%,
      rgba(57, 26, 122, 0.3) 95.4%
    );
    @apply p-8;
    @apply rounded-2xl;
    @apply font-medium;
    @apply text-lg;
  }

  .question {
    min-height: 80px;
    @apply cursor-pointer;
  }
  .answer {
    &-icon {
      @apply mt-2;
    }
  }
  .decrease-padding {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .slide-fade-enter-active {
    animation: slide-fade-in 0.3s;
  }
  .slide-fade-leave-active {
    animation: slide-fade-in 0.3s reverse;
  }
  @keyframes slide-fade-in {
    0% {
      transform: scaleY(0);
      max-height: 0px;
      transform-origin: top center;
    }
    100% {
      transform: scaleY(1);
      max-height: 1500px;
      transform-origin: top center;
    }
  }
  .chevron-toggle {
    transition: transform 0.3s;
  }

  .rotate-arrow {
    transform: rotate(180deg);
  }

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem;
  max-width: 100%;
  margin: 0 auto;
  background-color: rgba(57, 26, 122, 0.3);
}

.chain-currencies-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem;
}

.chain-currencies-icon img {
  width: 100%;
  max-width: 80px;
  height: auto;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    padding: 0.5rem;
  }

  .chain-currencies-icon {
    padding: 0.25rem;
  }

  .chain-currencies-icon img {
    max-width: 50px;
  }

  .chain-currencies-icon .text-base {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.25rem; 
    padding: 0.25rem; 
  }

  .chain-currencies-icon img {
    max-width: 30px;
  }

  .chain-currencies-icon .text-base {
    font-size: 0.65rem;
  }
}
}
</style>
